<template>
  <div>
    <div>
      <loader :isLoading="this.data_loaded"></loader>
    </div>
    <div class="mt-2">
      <button class="mb-2 btn btn-default btn-sm" @click="this.$router.go(-1)">
        Back
      </button>
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow w-100 p-4">
            <h3>Bank Transactions Management</h3>
            <div class="nav-wrapper">
              <ul
                class="nav nav-pills nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link mb-sm-3 mb-md-0 active"
                    id="tabs-icons-text-1-tab"
                    data-toggle="tab"
                    href="#tabs-icons-text-1"
                    role="tab"
                    aria-controls="tabs-icons-text-1"
                    aria-selected="true"
                    ><i class="ni ni-fat-delete mr-2"></i>Bank Transfer</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link mb-sm-3 mb-md-0"
                    id="tabs-icons-text-4-tab"
                    data-toggle="tab"
                    href="#tabs-icons-text-4"
                    role="tab"
                    aria-controls="tabs-icons-text-4"
                    aria-selected="false"
                    ><i class="ni ni-check-bold mr-2"></i>Bank Deposit</a
                  >
                </li>
              </ul>
            </div>

            <div class="card shadow">
              <div class="card-body">
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active bg-white"
                    id="tabs-icons-text-1"
                    role="tabpanel"
                    aria-labelledby="tabs-icons-text-1-tab"
                  >
                    <loader v-if="!data_loaded_cashout" />
                    <button
                      class="mb-2 btn btn-default btn-sm"
                      @click="
                        this.$router.push({
                          name: 'Bank flow',
                          params: {
                            uuid: this.$route.params.uuid,
                            status: 'credit',
                          },
                        })
                      "
                    >
                      Add Bank Transfer
                    </button>
                    <data-table
                      id="cashout"
                      v-if="data_loaded_cashout"
                      :options="dtb_options_cashout.extra_options"
                      :actions="dtb_options_cashout.actions"
                      :data="dtb_options_cashout.data"
                      :columns="dtb_options_cashout.columns"
                    />
                    <!-- display total cashout -->
                    <div class="row">
                      <div class="col-md-8"></div>
                      <div class="col-md-4">
                        <h2 v-if="total_cashout > 0">
                          Total Transfer: {{ total_cashout }} RWF
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade bg-white"
                    id="tabs-icons-text-4"
                    role="tabpanel"
                    aria-labelledby="tabs-icons-text-4-tab"
                  >
                    <loader v-if="!data_loaded_in" />
                    <button
                      class="mb-2 btn btn-default btn-sm"
                      @click="
                        this.$router.push({
                          name: 'Bank flow',
                          params: {
                            uuid: this.$route.params.uuid,
                            status: 'debit',
                          },
                        })
                      "
                    >
                      Add Bank Deposit
                    </button>
                    <data-table
                      id="cash_in"
                      v-if="data_loaded_in"
                      :options="dtb_options_cashin.extra_options"
                      :actions="dtb_options_cashin.actions"
                      :data="dtb_options_cashin.data"
                      :columns="dtb_options_cashin.columns"
                    />
                    <div class="row">
                      <div class="col-md-8"></div>
                      <div class="col-md-4">
                        <h2 v-if="total_cashin > 0">
                          Total Deposits: {{ total_cashin }} RWF
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-if="false">
          <div class="card shadow w-100 p-4">
            <div class="row mb-1 ">
              <div class="col-md-12 mb-4">
                <h3>Actions</h3>
              </div>
              <div class="col-md-6 mb-3">
                <strong>Is Active ({{ isActivate ? "Yes" : "No" }})</strong>
              </div>
              <div class="col-md-4">
                <label class="custom-toggle">
                  <input
                    id="change_visibility"
                    type="checkbox"
                    v-model="isActivate"
                  />
                  <span
                    class="custom-toggle-slider rounded-circle bg-white"
                  ></span>
                </label>
              </div>

              <div class="col-md-12 mb-3">
                <button
                  type="button"
                  class="btn btn-default btn-sm btn-block w-100"
                >
                  Update product
                </button>
              </div>
              <div class="col-md-12 mb-3">
                <button
                  type="button"
                  class="btn btn-default btn-sm btn-block w-100"
                  @click="this.delete_institution_clicked()"
                >
                  Delete product
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { alert } from "../../utils/alertUtils";
import Loader from "../../components/Loader";
import { doc, deleteDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import DataTable from "@/components/Datatable";

export default {
  name: "Product Accounting",
  components: {
    "data-table": DataTable,
    loader: Loader,
  },
  data() {
    return {
      url: "",
      acts_data_loaded: false,
      data_loaded: true,
      data: {},
      isActivate: true,

      data_loaded_cashout: false,
      data_loaded_in: false,

      total_cashin: 0,
      total_cashout: 0,

      dtb_options_cashout: {
        data: [],

        columns: [
          {
            title: "Added At",
            data: "date_inserted",
            render: (data) => {
              return data["date_inserted"]
                ? this.$date_format.fromFirestore(data["date_inserted"])
                : "N/A";
            },
          },
          { title: "Remark", data: "reason" },
          {
            title: "Amount",
            data: "amount",
            render: (data) => `${data["amount"]}`,
          },
        ],
        extra_options: {},
      },
      dtb_options_cashin: {
        data: [],

        columns: [
          {
            title: "Added At",
            data: "date_inserted",
            render: (data) => {
              return data["date_inserted"]
                ? this.$date_format.fromFirestore(data["date_inserted"])
                : "N/A";
            },
          },
          { title: "Detail", data: "remark" },
          {
            title: "Amount",
            data: "amount",
            render: (data) => `${data["amount"]}`,
          },
        ],
        extra_options: {},
      },
    };
  },

  methods: {
    fetch_bank_credits: function() {
      this.data_loaded_cashout = false;
      getDocs(collection(db, `banks/${this.$route.params.uuid}/credits`))
        .then((snapShots) => {
          snapShots.forEach((document) => {
            let service = document.data();
            this.total_cashout += parseInt(service["amount"]);
            service.uuid = document.id;
            this.dtb_options_cashout.data.push(service);
          });
        })
        .then(() => {
          this.data_loaded_cashout = true;
        })
        .catch(() => {
          this.data_loaded_cashout = false;
          alert.error("an error occured! please try again.");
        });
    },
    fetch_bank_debits: function() {
      this.data_loaded_in = false;
      getDocs(collection(db, `banks/${this.$route.params.uuid}/debits`))
        .then((snapShots) => {
          console.log(snapShots);
          snapShots.forEach((document) => {
            let service = document.data();
            this.total_cashin += parseInt(service["amount"]);
            service.uuid = document.id;
            this.dtb_options_cashin.data.push(service);
          });
        })
        .then(() => {
          this.data_loaded_in = true;
        })
        .catch(() => {
          this.data_loaded_in = false;
          alert.error("an error occured! please try again.");
        });
    },

    implement_delete_product: function(uuid) {
      const ref = doc(db, "products", uuid);
      deleteDoc(ref)
        .then(() => {
          this.dtb_options.data = [];
          this.fetch_news();
          alert.success_center("gusiba byakunze");
        })
        .catch(() => {
          alert.error("Error occured in deleting product");
        });
    },

    delete_product_clicked: function() {
      alert.promptDelete(
        this.implement_delete_product(this.$route.params.uuid)
      );
    },

    update_institution: function() {
      this.$router.push({
        name: "Institution Update",
        params: { id: this.$route.params.id },
      });
    },

    delete_institution_clicked: function() {
      alert.promptDelete(this.delete_institution);
    },
  },

  created() {
    this.fetch_bank_credits();
    this.fetch_bank_debits();
  },
};
</script>
<style lang="css" scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #154462;
}
.nav-pills .nav-link {
  color: #154462;
}
.nav-pills .nav-link:hover {
  color: #154462;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link:hover {
  color: white;
}
</style>
